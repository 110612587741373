import React from "react";
import { Helmet } from "react-helmet";
const ComingSoon = () => {
  const welcomeRef = React.useRef(null);

  const scrollToWelcome = () => {
    if (welcomeRef.current) {
      welcomeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited | Home
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>

      <div className="w-full h-screen flex flex-col justify-center items-center ">
        <div>
          <button>
            <img
              src="https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/logonew.png"
              alt="new"
            />
          </button>
        </div>
        <p className=" font-roboto text-2xl text-black mt-10">
          Under construction! Coming soon
        </p>
        <p className="absolute bottom-6 font-roboto text-xl text-black mt-10">
          For any queries, reach at connect@connectrpl.com
        </p>
      </div>
    </section>
  );
};

export default ComingSoon;
