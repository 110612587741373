import React from "react";
import Main from "../../../components/ui/sidebar/Main.tsx";
import Spacer from "../../../components/ui/Spacer.tsx";
import useApi from "../../../hooks/useApi.tsx";
import secureLocalStorage from "react-secure-storage";
import { GetClientSupportApi } from "../../../apis.ts";
import Card from "../../../components/common/SupportCard.tsx";

// Define the UserData interface
interface UserData {
  client_name: string;
  client_code: string;
  name: string;
  email: string;
}

// Define the ApiResponse interface
interface ApiResponse {
  user_name: string;
  user_email: string;
  manager_name: string;
  manager_email: string;
  manager_designation: string;
  manager_phone: string;
  user_designation: string;
}

const Support = () => {
  const { makeApiCall } = useApi();
  const [userData, setUserData] = React.useState<UserData | null>(null); // State to store user data
  const [responseData, setResponseData] = React.useState<ApiResponse | null>(
    null,
  ); // State to store API response data
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let data = secureLocalStorage.getItem("userinfo");
    if (data) {
      // Parse the data if necessary
      const parsedData: UserData = JSON.parse(data as string);
      setUserData(parsedData); // Set the user data in state
      if (parsedData.client_code) {
        setLoading(true);
        makeApiCall(GetClientSupportApi(parsedData.client_code))
          .then((response) => {
            if (response !== undefined) {
              console.log(response, "RESPONSE OF SUPPORT API");
              setResponseData(response); // Set the API response data in state
            }
          })
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
      }
    }
  }, [makeApiCall]);

  return (
    <div className="text-black bg-pageBackground px-10 min-h-screen">
      <Spacer size="sm" />
      <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%]">
        Support
      </p>
      <Spacer size="xs" />
      {loading ? (
        <p>Loading...</p>
      ) : responseData ? (
        <div className="flex flex-col">
          <Card
            user_name={responseData.user_name}
            user_email={responseData.user_email}
            designation={responseData?.user_designation}
            phone={responseData?.manager_phone}
            cardTitle="Key Account Manager"
            level={1}
          />
          <div className="w-full  mb-4 border-b-2 border-dashed " />
          <Card
            user_name={responseData.manager_name}
            user_email={responseData.manager_email}
            designation={responseData?.manager_designation}
            phone={responseData?.manager_phone}
            cardTitle="Senior Account Manager"
            level={2}
          />
          <div className="w-full  mb-4 border-b-2 border-dashed " />

          <div className="flex flex-row gap-x-4">
            <Card
              user_name={"Rohit Kakkar"}
              user_email={"rohit@connectrpl.com"}
              cardTitle="Director"
              designation={"Director"}
              level={3}
            />{" "}
            <Card
              user_name={"Ankush Bhan"}
              user_email={"ankush@connectrpl.com"}
              cardTitle="Director"
              designation={"Director"}
              level={3}
            />
          </div>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default Support;
