import React from "react";
import { Helmet } from "react-helmet";
import Contactform from "../../components/homeelments/Contactform";
import Header from "../../components/common/Header";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import AddressCardUs from "../../components/pages/reachus/AddressCard.tsx";
import TitleHead from "../../components/common/TitleHead.tsx";
const Reachus = () => {
  const addresses = [
    {
      image:
        "https://storage.googleapis.com/connectrpl_images/companywebsite/MumbaiOffice.png", // Assuming AddressImg1 is imported
      city: "Mumbai",
      address:
        "Link Road, Atul Projects, 604, D Wing Corporate Avenue, New, Andheri - Kurla Rd, Andheri East, Mumbai, Maharashtra 400093",
      phone: "+91 22 69527600",
      pocname: "Ashish Kumar",
      pocemail: "ashish.kumar@gmail.com",
      poclinkdin: "ankush@connectrpl.com",
      iframe:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.9307323932!2d72.85686547617576!3d19.110694450880604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c834f8096e6d%3A0xee87c37564263f1a!2sConnect%20Residuary%20Private%20Limited!5e0!3m2!1sen!2sin!4v1715772331652!5m2!1sen!2sin",
    },
    {
      image:
        "https://storage.googleapis.com/connectrpl_images/companywebsite/Delhi_office.png", // Assuming AddressImg1 is imported
      city: "New Delhi",
      address:
        "Unit No. 303, 3rd Floor, Rectangle-1, D-4, Saket District Centre, District Centre, Sector 6, Saket, New Delhi, Delhi 110017",
      phone: "+91 011 98765432",
      pocname: "Priya Mehra",
      pocemail: "priya.mehra@example.com",
      poclinkdin: "priyamehra",
      iframe:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.4172893181862!2d77.21166948875786!3d28.52717452627158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1b3dd4e4659%3A0xbcd00deafcb16741!2sConnect%20Residuary%20Private%20Limited!5e0!3m2!1sen!2sin!4v1716802563261!5m2!1sen!2sin",
    },
    {
      image:
        "https://storage.googleapis.com/connectrpl_images/companywebsite/AddressImg1.svg", // Assuming AddressImg1 is imported
      city: "Bengaluru",
      address:
        "Venus Hebron, No. 3, HRBR Layout, 4th G Main Road ,2nd Block, Kalyan Nagar, Bengaluru, Bengaluru Urban, Karnataka, 560043",
      phone: "",
      pocname: "Priya Mehra",
      pocemail: "priya.mehra@example.com",
      poclinkdin: "priyamehra",
      iframe:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3926776.2155519673!2d72.60991278099807!3d16.026919878374812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae176fddcb6579%3A0x3415265a1f82c6fd!2sConnect%20Residuary%20Private%20Limited!5e0!3m2!1sen!2sin!4v1716802662365!5m2!1sen!2sin",
    },
    {
      image:
        "https://storage.googleapis.com/connectrpl_images/companywebsite/ChennaiOffice.jpeg", // Assuming AddressImg1 is imported
      city: "Chennai ",
      address:
        "iSprout Business Centre, Kochar Jade, 5th Floor, Block No. 5, Cabin MD2, Plot No. SP-22, T-S No. 25, Ekkatuthangal, Guindy Industrial Estate, Chennai – 600 032.",
      phone: "",
      pocname: "Priya Mehra",
      pocemail: "priya.mehra@example.com",
      poclinkdin: "priyamehra",
      iframe:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1943.6789865044523!2d80.20134385048225!3d13.01286114503521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267e3886538cd%3A0x24b77e13ad11b107!2siSprout%20Business%20Centre%20%7C%20Coworking%20Space%20in%20Guindy%2C%20Chennai!5e0!3m2!1sen!2sin!4v1716802837954!5m2!1sen!2sin",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          reachus{" "}
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
        {/* Add any other meta tags as needed */}
      </Helmet>

      <div className="w-full ">
        <Header />
        <div className=" w-screen">
          <HeadingBanner
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/ContactBanner1.png"
            }
            text="Contact Us"
            desc={"A Complete End to End Leasing Solutions Company"}
          />
        </div>
        <div className="flex flex-col items-center justify-center ">
          <div className=" w-[80%] ">
            <p className=" text-base font-roboto w-full text-black text-center mt-5">
              A company formed by a group of professionals who have been
              connected with the equipment renting industry for over 36 years in
              India and Overseas. The prime business of the company is equipment
              renting based on Residual management capabilities. As an asset
              lifecycle management company, our focus is toengage with
              corporates to cater to their asset-based needs for expansion. Our
              transparent and consultative approach helps us to deliver renting
              solutions to meet customer requirements.
            </p>
          </div>
          <Contactform />
          <div className=" w-[80%] ">
            <TitleHead title="Our Locations" subtitle=" Our Offices" />

            <div>
              <div className="grid bg-white">
                {addresses.map((address, index) => (
                  <div className="my-10">
                    <AddressCardUs key={index} address={address} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reachus;
