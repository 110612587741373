import React from "react";
import TopNav from "../../components/TopNav";
import { Helmet } from "react-helmet";

const Csr = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited | csr{" "}
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
        {/* Add any other meta tags as needed */}
      </Helmet>
      <TopNav
        bannerurl="https://storage.googleapis.com/connectrpl-landing-pagev3/banner1%20.png"
        title="CSR"
        titledesc=""
        showreadmore={false}
      />

      <div className=" flex justify-center items-center">
        <div className=" w-[80%] ">
          <div
            style={{ offsetPosition: "normal" }}
            className="mb-20 text-zinc-800 px-4 relative"
          >
            <div className=" px-4 relative">
              <div className="md:mb-20">
                <h1 className="text-black text-[2.13rem] leading-9 font-semibold py-3 lg:ml-10">
                  <span className="text-black font-poppins">
                    Connecting toward a progressive society
                  </span>
                </h1>
                <p className="text-black text-base font-light pr-3 text-justify font-poppins lg:ml-10">
                  While achieving sustainable growth at Connect, we have
                  realized our need to contribute toward the society by
                  conducting certain corporate social activities as per company
                  policy. Amongst many things, we primarily support education of
                  students from weaker section of society, children that have
                  special needs, and promote causes like social empowerment,
                  training of Youth for Self-Employment, with a focus on
                  Pan-India grassroots development. We pro-actively engage our
                  self in initiatives of empowering traditionally disadvantaged
                  local communities and geographies for building progressive
                  society
                </p>
              </div>
              <div className="mb-8">
                <h3 className="text-black text-xl py-3 font-poppins lg:ml-10">
                  CSR initiatives:
                </h3>
                <p className="text-black text-base font-light pr-3 text-justify font-poppins lg:ml-10">
                  We endeavor constantly to track such Non-profit organizations
                  with whom we can work together and can make a difference to
                  attain our CSR goals
                </p>
              </div>
              <div className="-m-3.5">
                <div className="px-4 relative">
                  <div>
                    <a
                      href="https://www.connectrpl.com/pdf/CSR_POLICY_NEW.pdf "
                      className="text-white bg-red-600 text-xs px-4 relative rounded-3xl font-poppins lg:ml-10 p-4"
                    >
                      CORPORATE SOCIAL RESPONSIBILITY POLICY
                      <i className="cursor-pointer inline-block"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Csr;
