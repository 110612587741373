import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/common/Header";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import { Colors } from "../../assets/colors.js";
import ProfileCard from "../../components/common/ProfileCard.tsx";
import AddressCard from "../../components/pages/about/AddressCard.tsx";
import Contactform from "../../components/homeelments/Contactform.js";
import TitleHead from "../../components/common/TitleHead.tsx";

const Aboutus = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          aboutus
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
        {/* Add any other meta tags as needed */}
      </Helmet>

      <div className="w-full ">
        <Header />
        <div className=" w-screen">
          <HeadingBanner
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/AboutBanner.png"
            }
            text="About Connect"
            desc={
              " Building better leasing solutions  tailor made to your needs "
            }
          />
        </div>
        <div className="flex justify-center ">
          <div className=" w-[80%] ">
            <div>
              <p className=" text-base font-roboto font-light w-full text-black text-center mt-10">
                A company formed by a group of professionals who have been
                connected with the equipment renting industry for over 36 years
                in India and Overseas. The prime business of the company is
                equipment renting based on Residual management capabilities. As
                an asset lifecycle management company, our focus is toengage
                with corporates to cater to their asset-based needs for
                expansion. Our transparent and consultative approach helps us to
                deliver renting solutions to meet customer requirements.
              </p>
              <p className=" text-base font-roboto font-light w-full text-black text-center mt-5">
                We also offer integrated asset tracking solutions for clients to
                manage the rented assets across the organization. It helps the
                Client to visualize and analyze the current engagement with
                Connect.
              </p>
              <p className=" text-base font-roboto font-light w-full text-black text-center mt-5">
                No matter what asset class you need to procure, we can assist
                you by customizing our solution to help you optimize your cash
                flow and/or maximize your purchasing power. With our head office
                in Mumbai and aptly supported by sales offices in Delhi NCR,
                Chennai and Bangalore.
              </p>
              <p className=" text-base font-roboto w-full font-light text-black text-center mt-5">
                We cater to Pan India market through a well-established platform
                and network.
              </p>
            </div>

            <TitleHead title="Our Team" subtitle="Management Team" />

            <div className=" flex flex-row justify-center items-center gap-10 mt-8 ">
              <ProfileCard
                imageUrl="https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/ankush.png"
                name="Ankush Bhan"
                title="Founder & Director"
                text="ankush@connectrpl.com"
                profileUrl="https://www.linkedin.com/in/ankush-bhan-26b90b5/"
              />
              <ProfileCard
                imageUrl="https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/rohit.png"
                name="Rohit Kakkar"
                title="Founder & Director"
                text="rohit@connectrpl.com"
                profileUrl="https://www.linkedin.com/in/rohit-kakkar-38539816/"
              />
            </div>

            <div className="flex-shrink-0 px-4 text-[#1e0e62] mt-[10%] mb-1  sm:text-4xl text-left font-roboto text-5xl  leading-[64px] ">
              <span className="font-light  ">Our Culture</span>
            </div>
            <div className="flex">
              <div className="w-1/2  p-4">
                <p className=" text-black font-roboto text-base font-light">
                  Connect is about people, values, growing relationships, and
                  building a community both in real life and on the net. With 5
                  offices, across 5 states, and a team of 50+ motivated
                  individual contributing,
                </p>
                <p className=" text-black font-roboto text-base font-light mt-[3%]">
                  Connect takes pride in building a mutually inclusive, growth
                  focused work culture within the organisation.
                </p>
                <p className=" text-black font-roboto text-base font-light mt-[3%]">
                  Our policies synchronize the positive energies of our
                  resources by cultivating the professional and personal zest of
                  our workforce.
                </p>
                <p className=" text-black font-roboto text-base font-light mt-[3%]">
                  We believe that work is serious, but we also believe that if
                  we do not enjoy what we do, we can never motivate ourselves to
                  excel personally or professionally.
                </p>
              </div>
              <div className="w-1/2  p-4">
                <img
                  src={
                    "https://storage.googleapis.com/connectrpl_images/companywebsite/AboutSubBanner.png"
                  }
                  alt="Banner"
                  className="w-full h-auto"
                />
              </div>
            </div>

            <TitleHead title="Our Location" subtitle="Our Offices" />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-auto my-4 ">
              <AddressCard
                cardtitle={"Mumbai"}
                content={
                  "Link Road, Atul Projects, 604, D Wing Corporate Avenue, New, Andheri - Kurla Rd, Andheri East, Mumbai, Maharashtra 400093"
                }
                phone={"+91 22 69527600"}
                email={"connect@connectrpl.com"}
              />
              <AddressCard
                cardtitle={"Delhi"}
                content={
                  "Unit No. 303, 3rd Floor, Rectangle-1, D-4, Saket District Centre, District Centre, Sector 6, Saket, New Delhi, Delhi 110017"
                }
                phone={"+91 022 69527600"}
                email={"connect@connectrpl.com"}
              />
              <AddressCard
                cardtitle={"Bangalore"}
                content={
                  "Venus Hebron, No. 3, HRBR Layout, 4th G Main Road ,2nd Block, Kalyan Nagar, Bengaluru, Bengaluru Urban, Karnataka, 560043"
                }
                phone={"+91 022 69527600"}
                email={"connect@connectrpl.com"}
              />{" "}
              <AddressCard
                cardtitle={"Chennai"}
                content={
                  "iSprout Business Centre, Kochar Jade, 5th Floor,Block No. 5, Cabin MD2, Plot No. SP-22, T-S No. 25, Ekkatuthangal, Guindy Industrial Estate, Chennai – 600 032."
                }
                phone={"+91 022 69527600"}
                email={"connect@connectrpl.com"}
              />
            </div>

            <Contactform />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
