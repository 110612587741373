import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/common/Header";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import Contactform from "../../components/homeelments/Contactform";
import TitleHead from "../../components/common/TitleHead.tsx";

const PartnerWithUs = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          Partner with us
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>
      <div className="w-full ">
        <Header />
        <div className=" w-screen">
          <HeadingBanner
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/PartnersSubBanner.png"
            }
            text="Partner With Us"
            desc={"Our Solutions, your growth"}
          />
        </div>
        <div className="flex justify-center ">
          <div className=" w-[80%] ">
            <p className=" text-base font-light font-roboto w-full text-black text-center mt-[4%]">
              Connect prides in its 50+ member strong Client Relationship Team,
              present PAN India, who work with you to understand your business,
              your needs and create a leasing plan for you to help you achieve
              your objectives. Our team offices in Mumbai, Delhi, Bangalore,
              Chennai, Pune and Okhla proactively works with you and your team
              to deeply understand your requirements, and uses its 40+ years of
              industry expertise to build and propose a leasing plan tailor made
              for you.
            </p>
            <p className=" text-base font-light font-roboto w-full text-black text-center my-[4%]">
              Connect builds strong relationships based on personalized
              attention and superior service. Our service team will work with
              you one-on-one to handle the details, learn your preferences, and
              answer the phone when you call. Fill out the form and a member of
              our team will get back to you shortly.
            </p>

            <TitleHead title="Get in Touch with us" subtitle="Get in touch" />
            <Contactform />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWithUs;
