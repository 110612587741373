import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/ui/Input.tsx";
import Spacer from "../../../components/ui/Spacer.tsx";
import { LoadingIcon } from "../../../components/ui/Loading.tsx";
import { useNavigate } from "react-router-dom";
import useApi from "../../../hooks/useApi.tsx";
import { LoginApi } from "../../../apis.ts";
import useToast from "../../../hooks/useToast.tsx";
import useStore from "../../../hooks/store.tsx";
import secureLocalStorage from "react-secure-storage";

const INTIAL_VALUESLOGIN = {
  email: "",
  password: "",
};

const INTIAL_VALUESSENDOTPFORM = {
  email: "",
};

const INTIAL_VALUESVERIFY = {
  verification_code: "",
  password: "",
  confirm_password: "",
};

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [otpSent, setOtpSent] = React.useState(false);
  const navigate = useNavigate();
  const { makeApiCall } = useApi();
  const { showToast } = useToast();

  const { setAuthToken, setUser } = useStore((state) => ({
    setAuthToken: state.setAuthToken,
    setUser: state.setUser,
  }));

  const validationloginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid  email format")
      .required(" Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const validationSchema = Yup.object().shape({
    verification_code: Yup.string().required(" OTP is required"),
    password: Yup.string().required("Password is required"),
    confirm_password: Yup.string().required(
      "Confirm password same as password is required",
    ),
  });
  const toggleForgetPassword = React.useCallback(() => {
    setPassword(true);
  }, []);

  const handleLoginSubmit = React.useCallback(
    async ({ email, password }: typeof INTIAL_VALUESLOGIN) => {
      setLoading(true);
      try {
        const response = await makeApiCall(LoginApi(email, password));
        const { token }: { token: string } = response;
        if (response?.message === "success") {
          showToast("Login Successful!!", { type: "success" });

          // Save the token to local storage
          localStorage.setItem("authToken", token);
          secureLocalStorage.setItem("token", token);

          // Check if the token is saved correctly
          const savedToken = secureLocalStorage.getItem("token");

          if (savedToken) {
            setAuthToken(token);
            navigate("/dashboard");
          } else {
            showToast("Failed to save token. Please try again.", {
              type: "error",
            });
          }
        } else {
          showToast("Something went wrong!!", { type: "error" });
        }
      } catch (error) {
        showToast(
          error?.response?.data?.message != undefined
            ? error?.response?.data?.message
            : "error occurred!!",
          { type: "error" },
        );
      } finally {
        setLoading(false);
      }
    },
    [makeApiCall, navigate], // Ensure dependencies are listed
  );

  const handleSendOtpSubmit = React.useCallback(
    ({ email }: typeof INTIAL_VALUESSENDOTPFORM) => {},
    [],
  );

  const validationSchemaSendOtp = Yup.object().shape({
    email: Yup.string()
      .email("Invalid  email format")
      .required(" Email is required"),
  });

  const handleSendSetPassword = React.useCallback(
    ({
      verification_code,
      password,
      confirm_password,
    }: typeof INTIAL_VALUESVERIFY) => {},
    [],
  );

  return (
    <section className="bg-white ">
      <div className="flex flex-row justify-between">
        <div className="w-full  hidden md:block">
          <div className=" h-screen flex items-center justify-center">
            <div
              className="w-full h-full bg-center bg-no-repeat bg-cover"
              style={{
                backgroundImage: `url("https://storage.googleapis.com/website_blog_images/clientportalloginimage.svg")`,
              }}
            >
              <div className=" mt-[8%]">
                <div className=" flex w-full flex-col">
                  <img
                    src={
                      "https://connectelpl.com/_next/static/media/logocrpl.5ecbd723.svg"
                    }
                    alt="banner"
                    height={50}
                    className="cursor-pointer transfor self-center "
                  />
                  <Spacer size="xs" />
                </div>
                {!password ? (
                  <div>
                    <div className=" mx-auto max-w-2xl ">
                      <Formik
                        initialValues={INTIAL_VALUESLOGIN}
                        onSubmit={handleLoginSubmit}
                        validateOnBlur
                        validateOnChange
                        validationSchema={validationloginSchema}
                        enableReinitialize
                      >
                        <Form>
                          <div
                            style={{ borderColor: "#DADADA", width: "80%" }}
                            className="px-10 mx-auto max-w-xl    rounded-2xl"
                          >
                            <div className="flex w-full justify-center items-center">
                              <p
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 400,
                                  lineHeight: "29px",
                                  paddingTop: "10px",
                                }}
                                className="my-2 text-xl font-normal font-roboto text-gray-900"
                              >
                                Log In
                              </p>
                            </div>
                            <Input
                              label="Registered Email"
                              placeholder="Email"
                              name="email"
                            />
                            <Spacer size="xs" />

                            <Input
                              label="Password"
                              placeholder="Password"
                              name="password"
                            />
                            <Spacer size="sm" />
                          </div>
                          <Spacer size="sm" />

                          <div className="flex justify-center items-center">
                            {loading ? (
                              <button
                                disabled
                                style={{ backgroundColor: "#00006D" }}
                                type="button"
                                className=" focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg"
                              >
                                <div className="flex flex-row justify-center items-center">
                                  <div className="w-10 h-1" />

                                  <LoadingIcon />
                                  <p
                                    style={{
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontSize: "15px",
                                      fontWeight: "400",
                                      paddingRight: 64,
                                      paddingLeft: 10,
                                      color: "#fff",
                                      textAlign: "center",
                                    }}
                                  >
                                    Loading ...
                                  </p>
                                </div>
                              </button>
                            ) : (
                              <button
                                type="submit"
                                style={{ backgroundColor: "#00006D" }}
                                className=" focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg "
                              >
                                <p
                                  style={{
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    paddingLeft: 64,
                                    paddingRight: 64,
                                    color: "#fff",
                                    textAlign: "center",
                                  }}
                                >
                                  Login
                                </p>
                              </button>
                            )}
                          </div>
                          <Spacer size="sm" />
                          <div className="flex justify-center items-center">
                            <p className=" text-black font-roboto font-normal text-xs">
                              For a demo account, please contact
                              connect@connectrpl.com
                            </p>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                ) : !otpSent ? (
                  <div>
                    <div className=" ">
                      <div
                        style={{ paddingLeft: "30px", paddingRight: "30px" }}
                        className=" mx-auto max-w-2xl "
                      >
                        <Formik
                          initialValues={INTIAL_VALUESSENDOTPFORM}
                          onSubmit={handleSendOtpSubmit}
                          validateOnBlur
                          validateOnChange
                          validationSchema={validationSchemaSendOtp}
                          enableReinitialize
                        >
                          <Form>
                            <div
                              style={{ borderColor: "#DADADA", width: "80%" }}
                              className="px-10  mx-auto max-w-xl border   rounded-2xl"
                            >
                              <div className="flex w-full justify-center items-center">
                                <p
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    lineHeight: "29px",
                                    paddingTop: "10px",
                                  }}
                                  className="my-2 text-xl font-normal font-roboto text-gray-900"
                                >
                                  Forget Password
                                </p>
                              </div>
                              <Input
                                label="Enter Email"
                                placeholder="Email"
                                name="email"
                              />
                              <Spacer size="sm" />
                            </div>
                            <Spacer size="sm" />

                            <div className="flex justify-center items-center ml-16">
                              {loading ? (
                                <button
                                  disabled
                                  type="button"
                                  style={{ backgroundColor: "#00006D" }}
                                  className=" focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg ml-16 "
                                >
                                  <LoadingIcon />
                                  <p
                                    style={{
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontSize: "15px",
                                      fontWeight: "400",
                                      paddingLeft: 64,
                                      paddingRight: 64,
                                      color: "#fff",
                                      textAlign: "center",
                                    }}
                                  >
                                    Loading...
                                  </p>
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  style={{ backgroundColor: "#00006D" }}
                                  className=" focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg  "
                                >
                                  <p
                                    style={{
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      fontSize: "15px",
                                      fontWeight: "400",
                                      paddingLeft: 64,
                                      paddingRight: 64,
                                      color: "#fff",
                                      textAlign: "center",
                                    }}
                                  >
                                    Submit
                                  </p>
                                </button>
                              )}
                            </div>
                            <div className="flex justify-center items-center">
                              <p className="my-4  text-base font-medium text-gray-900 "></p>
                            </div>
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className=" ">
                        <div
                          style={{ paddingLeft: "30px", paddingRight: "30px" }}
                          className=" mx-auto max-w-2xl "
                        >
                          <Formik
                            initialValues={INTIAL_VALUESVERIFY}
                            onSubmit={handleSendSetPassword}
                            validateOnBlur
                            validateOnChange
                            validationSchema={validationSchema}
                            enableReinitialize
                          >
                            <Form>
                              <div
                                style={{ borderColor: "#DADADA", width: "80%" }}
                                className="px-10 mx-auto max-w-xl border   rounded-2xl"
                              >
                                <div className="flex w-full justify-center items-center">
                                  <p
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: 400,
                                      lineHeight: "29px",
                                      paddingTop: "10px",
                                    }}
                                    className="my-2 text-xl font-normal font-roboto text-gray-900"
                                  >
                                    Verify Email
                                  </p>
                                </div>
                                <Input
                                  label="Enter OTP Received on your email"
                                  placeholder="Enter OTP"
                                  name="verification_code"
                                />
                                <Spacer size="xs" />

                                <Input
                                  label="Create Password"
                                  placeholder="enter Password"
                                  name="password"
                                  type="password"
                                />
                                <Spacer size="xs" />

                                <Input
                                  label="Confirm Password"
                                  placeholder="confirm password"
                                  name="confirm_password"
                                  type="password"
                                />
                                <Spacer size="sm" />
                              </div>
                              <Spacer size="sm" />

                              <div className="flex justify-center items-center ml-16">
                                {loading ? (
                                  <button
                                    disabled
                                    style={{ backgroundColor: "#4EB2EF" }}
                                    type="button"
                                    className=" focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg ml-16 "
                                  >
                                    <LoadingIcon />
                                    <p
                                      style={{
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        fontSize: "15px",
                                        fontWeight: "400",
                                        paddingLeft: 64,
                                        paddingRight: 64,
                                        color: "#fff",
                                        textAlign: "center",
                                      }}
                                    >
                                      Loading ...
                                    </p>{" "}
                                  </button>
                                ) : (
                                  <button
                                    style={{ backgroundColor: "#4EB2EF" }}
                                    type="submit"
                                    className=" focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg  "
                                  >
                                    <p
                                      style={{
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        fontSize: "15px",
                                        fontWeight: "400",
                                        paddingLeft: 64,
                                        paddingRight: 64,
                                        color: "#fff",
                                        textAlign: "center",
                                      }}
                                    >
                                      Submit
                                    </p>
                                  </button>
                                )}
                              </div>
                            </Form>
                          </Formik>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="absolute z-10 bottom-0">
              <div className="flex flex-row justify-end  w-screen px-5">
                <p className="font-light text-sm font-roboto text-textColorSecondary">
                  Copyright © 2023 - 2024 Connect Residuary Private Limited.
                  All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
