import React, { useState } from "react";
import axios from "axios";
import Loader from "../common/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Phone from "../../assets/images/phone.svg";
import Mail from "../../assets/images/mail.svg";

const Contactform = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const notify = (text) => toast.success(text);
  const notifyfail = (text) => toast.error(text);

  const payload = {
    name: formData.name,
    recipient: formData.email,
    subject: "Query Received by Connect Residuary Private Limited",
    body: formData.message,
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      console.log(payload);
      const response = await axios.post(
        "https://feedbackservice-u5ab3vfhaa-uw.a.run.app/sendemail",
        payload,
      );
      setIsLoading(false);
      notify(" Message sent!");
    } catch (error) {
      setIsLoading(false);
      notifyfail("Error occurred!!");
      setError(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="w-full mt-20 mb-10 flex justify-center">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-[80%] ">
        <div className="p-4 flex justify-start flex-col ">
          <div className="w-full mb-3 ">
            <img
              src={
                "https://storage.googleapis.com/connectrpl_images/companywebsite/contact.png"
              }
              alt="Service Image"
              className="h-[100%]"
            />
          </div>

          <div className="flex-shrink-0 text-[#1e0e62] text-xl sm:text-4xl text-left font-roboto font-light ">
            Get in Touch with us
          </div>
          <div className="hidden justify-end mt-2 lg:block text-sm ">
            <div className="flex items-center flex-row">
              <img src={Phone} alt="React Logo" className="mt-1 mr-1" />
              <p className="text-neutral-700 font-roboto font-normal text-xs sm:text-base md:text-base">
                +91 22 69527600
              </p>
            </div>
          </div>
          <div className="hidden justify-end mt-2 lg:block text-sm">
            <div className="flex items-center flex-row ">
              <img src={Mail} alt="React Logo" className="mt-1 mr-2" />
              <p className="text-neutral-700 font-roboto font-normal text-xs sm:text-base md:text-base">
                connect@connectrpl.com
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <form
            onSubmit={handleSubmit}
            className="relative flex flex-col justify-center items-center mt-2"
          >
            <div className="flex flex-col justify-between mb-2 w-3/4 sm:w-full">
              <input
                className="shadow py-3 appearance-none text-base font-roboto rounded  outline-none focus:ring-0 focus:ring-transparent focus:outline-none my-2"
                id="name"
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />

              <input
                className="shadow py-3  appearance-none text-base font-roboto rounded outline-none focus:ring-0 focus:ring-transparent focus:outline-none my-2  "
                id="email"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="flex flex-row justify-center items-center mt-2 mb-4  w-3/4 sm:w-full h-40  ">
              <textarea
                rows="6"
                className=" w-full h-full shadow appearance-none border rounded  focus:outline-none focus:shadow-outline  outline-none focus:ring-0 focus:ring-transparent "
                id="message"
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>

            <div className="flex items-center justify-center  w-[100%]">
              <button
                className=" rounded   w-full bg-[#00006A] font-light  text-white font-roboto py-3 focus:outline-none focus:shadow-outline"
                type="submit"
              >
                {isLoading ? <Loader /> : <div> Send Message</div>}
              </button>
            </div>
          </form>{" "}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contactform;
