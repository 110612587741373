import React from "react";
import Main from "../../../components/ui/sidebar/Main.tsx";
import InvoiceNumbersList from "../../../components/pages/client/invoice-number-list/rentalSchedule/rental-number-list/List.tsx";
import { ClientInvoiceNumbersList } from "../../../types.ts";
import Spacer from "../../../components/ui/Spacer.tsx";
import secureLocalStorage from "react-secure-storage";
import useApi from "../../../hooks/useApi.tsx";
import { GetInvoiceListApi } from "../../../apis.ts";

// Define the UserData interface
interface UserData {
  client_name: string;
  client_code: string;
  name: string;
  email: string;
}
const Invoices = () => {
  const { makeApiCall } = useApi();

  const [clientInvoiceNumbers, setClientInvoiceNumbers] = React.useState<
    ClientInvoiceNumbersList[]
  >([]);
  const [invoiceLoading, setInvoiceLoading] = React.useState(false);
  const [mraLink, setMraLink] = React.useState<undefined | string>(undefined);
  const [userData, setUserData] = React.useState<UserData | null>(null); // State to store user data

  const downloadDocument = React.useCallback((name: string, url: string) => {
    const pdfUrl = url;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank"; // Open in a new tab/window
    link.download = `${name}.pdf`; // Specify the desired file name for the downloaded PDF
    link.click();
  }, []);

  const handleAddInvoice = React.useCallback(() => {}, []);

  // Rental schedule get API call
  React.useEffect(() => {
    let data = secureLocalStorage.getItem("userinfo");
    if (data) {
      // Parse the data if necessary
      const parsedData: UserData = JSON.parse(data as string);
      setUserData(parsedData); // Set the user data in state
      if (parsedData.client_code) {
        setInvoiceLoading(true);
        makeApiCall(GetInvoiceListApi(parsedData.client_code))
          .then((response) => {
            if (response !== undefined) {
              setClientInvoiceNumbers(response?.data);
            }
          })
          .catch((error) => console.error(error))
          .finally(() => setInvoiceLoading(false));
      }
    }
  }, [makeApiCall]);

  return (
    <div className="text-black bg-pageBackground px-10 min-h-screen">
      <Spacer size="sm" />
      <InvoiceNumbersList
        invoiceNumbers={clientInvoiceNumbers}
        loading={invoiceLoading}
        downloadInvoice={downloadDocument}
        onUpdateInvoice={handleAddInvoice}
        mraUrl={mraLink ?? ""}
      />
    </div>
  );
};

export default Invoices;
