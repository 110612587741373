import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Providers } from "./redux/providers";
import Paths from "./router";
import { useLocation } from "react-router-dom";
import { dashPaths, hiddenRoutes } from "./assets/data";
import Main from "./components/ui/sidebar/Main.tsx";
import DashPaths from "./dashrouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const { pathname } = useLocation();
  // const hideHeaderFooter = hiddenRoutes.includes(pathname);

  const isHiddenRoute = (pathname, hiddenRoutes) => {
    return hiddenRoutes.some((route) => {
      const routePattern = new RegExp(`^${route.replace(/:\w+/g, "\\w+")}$`);
      return routePattern.test(pathname);
    });
  };

  const isDynamicRoute = (pathname, paths) => {
    return paths.some((path) => {
      const pathPattern = new RegExp(`^${path.replace(/:\w+/g, "\\w+")}$`);
      return pathPattern.test(pathname);
    });
  };

  const hideHeaderFooter = isHiddenRoute(pathname, hiddenRoutes);

  const isDashPath = isDynamicRoute(pathname, dashPaths);

  return (
    <div>
      <div className="flex flex-col min-h-screen">
        {/* {!hideHeaderFooter && <Header />} */}
        <Providers>
          <div className="flex-grow">
            {!isDashPath ? (
              <Paths />
            ) : (
              <Main>
                <DashPaths />
              </Main>
            )}
          </div>
        </Providers>
        {/* {!hideHeaderFooter && <Footer />} */}
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
