import React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { LatestUpdateList, UpcomingQcType } from "../../../types.ts";
import useApi from "../../../hooks/useApi.tsx";
import secureLocalStorage from "react-secure-storage";
import {
  GetClientSupportApi,
  GetMraUrlApi,
  GetUpcomingQcClient,
  GetlatestListApi,
} from "../../../apis.ts";
import {
  addDays,
  getDateThreeMonthsAhead,
  getDateTwoMonthsAgo,
} from "../../../utils/utils.ts";
import MyDoughnutChart from "../../../components/common/DoughnutChart.js";
import { delay } from "framer-motion";

Chart.register(CategoryScale);

// Define the UserData interface
interface UserData {
  client_name: string;
  client_code: string;
  name: string;
  email: string;
}

// Define the ApiResponse interface
interface ApiResponse {
  user_name: string;
  user_email: string;
  manager_name: string;
  manager_email: string;
}

const Dashboard = () => {
  const MAX_RETRIES = 5;
  const RETRY_DELAY = 1000; // 1 second

  const { makeApiCall } = useApi();

  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState<UserData | null>(null);
  const [upcomingQc, setUpcomingQcData] = React.useState<UpcomingQcType[]>([]);
  const [latestUpdateData, setLatestUpdateData] = React.useState<
    LatestUpdateList[]
  >([]);
  const [deals, setDeals] = React.useState(0);
  const [disbursement, setDisbursement] = React.useState(0);
  const [responseData, setResponseData] = React.useState<ApiResponse | null>(
    null,
  ); // State to store API response data

  // Helper function to format dates
  const formatDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split("-");
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  };

  // Function to fetch upcoming QC data
  const upcomingQcFetchApi = React.useCallback(
    (start_date: string, end_date: string, client_code: string) => {
      setLoading(true);
      return makeApiCall(
        GetUpcomingQcClient(start_date, addDays(end_date, 1), client_code),
      )
        .then((response) => {
          setUpcomingQcData(response?.data);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    },
    [makeApiCall],
  );

  // Function to fetch user data
  const userDataApi = React.useCallback(
    (client_code: string) => {
      setLoading(true);
      return makeApiCall(GetMraUrlApi(client_code))
        .then((response) => {
          setDeals(Number(response?.data?.TotalValue));
          setDisbursement(Number(response?.data?.DisbursedValue));
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    },
    [makeApiCall],
  );

  // Function to fetch latest updates
  const getLatestUpdates = React.useCallback(() => {
    setLoading(true);
    return makeApiCall(GetlatestListApi())
      .then((response) => {
        setLatestUpdateData(response?.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  // useEffect to fetch initial data
  React.useEffect(() => {
    const fetchData = async () => {
      let retries = 0;
      let data = secureLocalStorage.getItem("userinfo");

      while (!data && retries < MAX_RETRIES) {
        console.log(`Retry ${retries + 1}: No data present, retrying...`);
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY)); // Wait for 1 second before retrying
        data = secureLocalStorage.getItem("userinfo");
        retries += 1;
      }

      if (!data) {
        console.log("No data present after retries, navigating to login page");
        return;
      }

      console.log("Data present:", data);

      const parsedData: UserData = JSON.parse(data as string);
      setUserData(parsedData);

      if (parsedData?.client_code) {
        try {
          console.log("Client code present:", parsedData?.client_code);

          await makeApiCall(GetClientSupportApi(parsedData.client_code))
            .then((response) => {
              if (response !== undefined) {
                setResponseData(response);
              }
            })
            .catch((error) => console.error(error));

          await upcomingQcFetchApi(
            getDateTwoMonthsAgo(),
            getDateThreeMonthsAhead(),
            parsedData?.client_code,
          );

          await getLatestUpdates();

          await userDataApi(parsedData.client_code);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [makeApiCall, upcomingQcFetchApi, getLatestUpdates, userDataApi]);

  return (
    <div className="text-black bg-pageBackground px-10 min-h-screen">
      <Spacer size="sm" />
      <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%] mb-[2%]">
        Dashboard
      </p>
      <div className="flex flex-row  w-full h-screen gap-x-2 mb-6">
        <div className="flex flex-col gap-y-2 mr-2  w-1/2 h-screen ">
          <div className="flex-1 flex flex-col rounded-md bg-white shadow-box">
            <p className="px-[4%] py-[2%]  text-base leading-8 font-roboto font-medium text-textColorSecondary">
              Disbursement Tracker
            </p>
            <div className="w-[100%] h-[80%] flex justify-center items-center">
              <MyDoughnutChart deals={deals} disbursement={disbursement} />
            </div>
          </div>
          <div className=" flex-1 flex rounded-md">
            {/* <div className="w-full">
              <p className="px-[8%] py-[3%] text-black text-base leading-8 font-roboto font-medium">
                Documents Vault
              </p>

              <div className="ml-[8%] w-full">
                <div className="w-[80%]  flex flex-row justify-between items-center px-4 border-b-1 border-gray-300 my-2">
                  <p className="text-black text-base leading-8 font-roboto font-normal underline">
                    MRA
                  </p>
                  <TbDownload className="text-linkColor" />
                </div>
                <div className="w-[80%]  flex flex-row justify-between items-center px-4 border-b-1 border-gray-300 my-2">
                  <p className="text-black text-base leading-8 font-roboto font-normal underline">
                    NQA
                  </p>
                  <TbDownload className="text-linkColor" />
                </div>
                <div className="w-[80%]  flex flex-row justify-between items-center px-4 border-b-1 border-gray-300 my-2">
                  <p className="text-black text-base leading-8 font-roboto font-normal underline">
                    Sanction Letter
                  </p>
                  <TbDownload className="text-linkColor" />
                </div>
                <div className="w-[80%]  flex flex-row justify-between items-center px-4 border-b-1 border-gray-300 my-2">
                  <p className="text-black text-base leading-8 font-roboto font-normal underline">
                    KYC
                  </p>
                  <TbDownload className="text-linkColor" />
                </div>
                <div className="w-[80%]  flex flex-row justify-between items-center px-4 border-b-1 border-gray-300 my-2">
                  <p className="text-black text-base leading-8 font-roboto font-normal underline">
                    Others
                  </p>
                  <TbDownload className="text-linkColor" />
                </div>
              </div>
            </div>{" "} */}
          </div>
        </div>

        <div className="flex flex-col gap-3 w-1/2 h-screen">
          <div className=" flex-1 flex rounded-md bg-white shadow-box">
            <div>
              <p className="p-[8%] text-textColorSecondary text-base leading-8 font-roboto font-medium">
                Quick Support
              </p>

              {loading ? (
                <p>Loading...</p>
              ) : responseData ? (
                <>
                  <div className="bg-white w-[100%] py-2 px-4 mx-auto rounded-lg">
                    <div>
                      <div className="ml-[8%]  w-full">
                        <p className="text-black text-sm leading-8 font-roboto font-light">
                          Name:{" "}
                          <span className="font-semibold">
                            {responseData?.user_name}
                          </span>
                        </p>
                        <p className="text-black text-sm leading-8 font-roboto font-light">
                          Email:{" "}
                          <span className="font-semibold">
                            {responseData?.user_email}
                          </span>
                        </p>
                        {/* <p className="text-black text-sm leading-8 font-roboto font-light">
                          Phone:{" "}
                          <span className="font-semibold">+91-9002307151 </span>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="bg-white w-[100%] py-2 px-4 mx-auto rounded-lg">
                  <p>No data available</p>
                </div>
              )}
            </div>{" "}
          </div>
          <div className="bg-white flex-1 flex rounded-md overflow-y-auto shadow-box">
            <div>
              <p className="p-[8%] pb-[1%] text-textColorSecondary text-base leading-8 font-roboto font-medium">
                Upcoming Events
              </p>
              <div className="ml-[8%] py-5 w-full">
                {upcomingQc?.map((item) => (
                  <p
                    key={item.id}
                    className="text-black text-sm leading-8 font-roboto font-light py-1"
                  >
                    <span className="font-extrabold text-lg mr-1">&#8226;</span>{" "}
                    Schedule Number {item.schedule_number} expiring on{" "}
                    {formatDate(item.end_date).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className=" flex-1 flex rounded-md bg-white shadow-box">
            <div className="w-full">
              <p className="px-[8%] py-[4%] text-textColorSecondary text-base leading-8 font-roboto font-medium">
                Latest Updates from Connect
              </p>
              <div className="ml-[8%]  w-full">
                {loading ? (
                  <p>Loading...</p>
                ) : latestUpdateData.length > 0 ? (
                  <>
                    {latestUpdateData?.map((item, index) => (
                      <p
                        key={index}
                        className="text-black text-base leading-8 font-roboto font-light"
                      >
                        <a
                          href={item.Link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.Header}
                        </a>
                      </p>
                    ))}
                  </>
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
