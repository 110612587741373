import * as React from "react";
import Row from "../../../../ui/Row.tsx";
import { ClientRentalNumbersList } from "../../../../../types.ts";
import { Tooltip } from "@nextui-org/react";
import Button from "../../../../ui/Button.tsx";

interface Props {
  item: ClientRentalNumbersList;
  downloadRental: (name: string, url: string) => void;
}

export default function Action({ item, downloadRental }: Props) {
  const handleDownloadRental = React.useCallback(() => {
    downloadRental(item?.ScheduleNumber, item?.DocumentUrl);
  }, [downloadRental, item?.ScheduleNumber, item?.DocumentUrl]);

  return (
    <Row>
      <a
        href="#"
        onClick={handleDownloadRental}
        className="text-textColorGrey cursor-pointer font-roboto"
      >
        Download
      </a>
      <a
        href="#"
        className="text-textColorGrey cursor-pointer font-roboto ml-2"
      >
        Extend
      </a>
    </Row>
  );
}
