// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';

// Import the functions you need from the SDKs you need

// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCTaieidzwRIxgE-T_GxJ5_Woz7pgP9B4g",
  authDomain: "connectrpl-87785.firebaseapp.com",
  databaseURL: "https://connectrpl-87785-default-rtdb.firebaseio.com",
  projectId: "connectrpl-87785",
  storageBucket: "connectrpl-87785.appspot.com",
  messagingSenderId: "583464823285",
  appId: "1:583464823285:web:700cd3cc36ecf4665218bc",
  measurementId: "G-1ZHCNK9LWN",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// // if (!firebase.getApps.length) {
// //   firebase.initializeApp(firebaseConfig);
// // } else {
// //   firebase.app();
// // }

// if (!getApps().length) {
//   const app = initializeApp(firebaseConfig);
// }else{

// }

// const firestore = firebase.firestore();
// const auth = firebase.auth();

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

// Initialize services
const firestore = getFirestore(firebase);
const auth = getAuth(firebase);
// const base = Rebase.createClass(firestore);
// const base = Rebase.createClass(firebase.database())
// Example function to get documents from a Firestore collection
async function getItems() {
  const querySnapshot = await getDocs(collection(firestore, "blogs"));
  const items = [];
  querySnapshot.forEach((doc) => {
    items.push({ id: doc.id, ...doc.data() });
  });
  return items;
}

// Fetch a document
const docRef = doc(firestore, "blogs", "fHJfgXn4Ihwmbiwz2wUR");
getDoc(docRef)
  .then((docSnap) => {
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
    } else {
      console.log("No such document!");
    }
  })
  .catch((error) => {
    console.error("Error fetching document: ", error);
  });

// export default base;

// named export
export { firebase, auth, firestore, getItems };

// const firebaseConfig = {
//     apiKey: process.env.FIREBASE_API_KEY,
//     authDomain: process.env.FIREBASE_AUTH_DOMIAN,
//     databaseURL: process.env.FIREBASE_DATABASE_URL,
//     projectId:  process.env.FIREBASE_PROJECT_ID,
//     storageBucket:  process.env.FIREBASE_STORAGE_BUCKET ,
//     messagingSenderId: process.env.FIREBASE_MESSAGIN_SENDER_ID,
//     appId: process.env.FIREBASE_APPID,
//     measurementId: process.env.FIREBASE_MEASUREMENT_ID
//   };
