import React, { useState, useEffect } from "react";
import TopNav from "../../components/TopNav";
import { Helmet } from "react-helmet";

// router
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { useDispatch } from "react-redux";
import { updateblogs } from "../../redux/slice";
import { firebase, firestore } from "../../lib/db";

const Blogpage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  const [game, setGame] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState({
    title: "",
    content: "",
    description: "--",
    image: "image",
    createdAt: "",
  });

  var datablog = useSelector((state) => {
    // setLocaldata(data);
    return state;
  });

  // Full URL of the current page
  const fullURL = window.location.href;

  // Hostname (domain)
  const hostname = window.location.hostname;

  // Pathname (the part of the URL following the domain)
  const pathname = window.location.pathname;

  // Query string (starting with ?)
  const queryString = window.location.search;

  // Protocol (http or https)
  const protocol = window.location.protocol;

  const fetchBlogs = async () => {
    const querySnapshot = await getDocs(collection(firestore, "blogs"));
    const blogsArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      title: doc.data().title, // Assuming 'title' is a field in your Firestore document
      content: doc.data().content, // Assuming 'content' is a field
      createdAt: doc.data().createdAt,
      description: doc.data().description,
      image: doc.data().image,
      updatedAt: doc.data().updatedAt,
      slug: doc.data().slug,
      metadata: doc.data().metadata,
      author: doc.data().author,
      // Map other fields as needed
    }));

    datablog = blogsArray;

    dispatch(updateblogs(blogsArray));
  };

  useEffect(() => {
    if (datablog.blogs.length == 0) {
      fetchBlogs();
    }

    // Assuming that 'datablog.blogs' contains the array of blogs
    if (datablog && datablog.blogs) {
      // Find the blog by id
      const foundBlog = datablog.blogs.find((blog) => blog.slug === slug);
      if (foundBlog) {
        setSelectedBlog(foundBlog);
      } else {
        console.log("Blog not found");
      }
    }
  }, [datablog, slug]);

  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited | blog
        </title>
        <link rel="canonical" href={fullURL} />

        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
        {/* Add any other meta tags as needed */}
      </Helmet>
      <div>
        <TopNav
          bannerurl="https://storage.googleapis.com/connectrpl-landing-pagev3/banner1%20.png"
          title={selectedBlog?.title || ""}
          showreadmore={false}
        />
        {/* <div className="ml-10 p-7">
        <p>{blogData.description}</p>
        <p>{blogData.content}</p>
      </div> */}

        <div className="max-w-3xl mx-auto p-4 ">
          {/* <h1 className="text-3xl font-bold mb-4">{selectedBlog?.title || "Default Title"}</h1> */}
          <div className="flex flex-row justify-between mb-5">
            <p className="mt-2 text-black font-semibold">
              Published By {selectedBlog.author}
            </p>

            <p className="mt-2 text-gray-700"> {selectedBlog.createdAt}</p>
          </div>
          {selectedBlog.image && (
            <img
              className="w-full mb-4"
              src={selectedBlog.image}
              alt={selectedBlog.title}
            />
          )}
          <div className="text-gray-800 text-lg whitespace-pre-line">
            {selectedBlog.content}
          </div>
          {/* Add more details as needed */}
        </div>
      </div>
    </div>
  );
};

export default Blogpage;
