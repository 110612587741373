import React from "react";
import TopNav from "../../components/TopNav";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Asset Leasing and Financing | Connect Residuary Private Limited |
          industries{" "}
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
        {/* Add any other meta tags as needed */}
      </Helmet>{" "}
      <section style={{ offsetPosition: "normal" }} className="text-zinc-800">
        <div className="m-auto">
          <TopNav
            bannerurl="https://storage.googleapis.com/connectrpl-landing-pagev3/banner1%20.png"
            title="Privacy Policy"
            titledesc=""
            showreadmore={false}
          />

          <div className=" flex justify-center items-center">
            <div className=" w-[80%] "></div>
          </div>
        </div>
        <div className="w-[100%] h-20"></div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
