import React from "react";
import { Link } from "react-router-dom";
import { Article } from "../../../types";
import { Colors } from "../../../assets/colors";

interface Props {
  blog: Article;
}

const BlogCard = ({ blog }: Props) => {
  return (
    <>
      <div className="flex justify-center border ">
        <div className="text-zinc-800 relative mb-4 px    rounded-md  w-[100%] ">
          {/* <img
            src={blog?.image}
            alt={blog?.title}
            className="w-full h-[40%] mb-4 rounded-md"
          /> */}
          <div
            style={{ width: "100%", height: "200px" }}
            className="mb-4 rounded-md overflow-hidden"
          >
            <img
              src={blog?.image}
              alt={blog?.title}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex flex-row justify-start items-center px-2 mb-2">
            <p className="text-black font-light text-sm font-roboto">
              {blog?.author}
            </p>
            <p className="text-black font-light text-xs font-roboto ml-4">
              {blog?.createdAt}
            </p>
          </div>

          <p className="text-black px-2 text-xl font-roboto font-medium mb-4">
            <Link to={`/post/${blog?.slug}`}>{blog?.title}</Link>
          </p>
          <p className="text-black text-base px-2 leading-5 font-light text-justify font-roboto w-[80%]">
            <Link to={`/post/${blog?.slug}`}>{blog?.description}</Link>
          </p>
          {/* <p className="px-2  text-black text-sm font-roboto">
            {blog.content.substring(0, 100)}...
          </p> */}
          {/* Read More */}
          <p
            style={{ offsetPosition: "normal", color: Colors.buttonprimary }}
            className="px-2 underline font-roboto font-normal text-base"
          >
            Read more
          </p>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
