import * as React from "react";
import Body from "./Body.tsx";
import { ToastContainer } from "react-toastify";
import DashHeader from "../sidebar/DashHeader.tsx";
import Sidebar, { SidebarItem } from "./SideBarNew.tsx";
import {
  DashboardSVG,
  RentalScheduleSVG,
  InventorySVG,
  InvoicesSVG,
  SupportSVG,
  ContactSVG,
  DocumentSVG,
  NewBusinessSVG,
  Automation,
} from "../../../assets/images/Images.js";
import Bottombar from "./Bottombar.tsx";
import { useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

export default function Main({ children }: Props) {
  const [expandedMain, setExpandedMain] = React.useState(true);

  return (
    <>
      <ToastContainer />
      <div
        className={`fixed top-0 left-0 right-0 transition-all w-screen ${
          expandedMain ? "sm:ml-0" : "sm:ml-0"
        }`}
      >
        <DashHeader />
      </div>
      <Body sideView={<SidebarNew setExpandedMain={setExpandedMain} />}>
        <div className="p-0 bg-white">
          <div
            className={`mt-12 transition-all ${
              expandedMain ? "sm:ml-[18%]" : "sm:ml-24"
            }`}
          >
            {children}
          </div>
        </div>
      </Body>
      <Bottombar />
    </>
  );
}

interface SidebarNewProps {
  setExpandedMain: (expanded: boolean) => void;
}

function SidebarNew({ setExpandedMain }: SidebarNewProps) {
  const { pathname } = useLocation();
  const inventoryPaths = ["/dashboard/inventory", "/dashboard/inventorylist"];

  const isActivePath = (pathname: string, paths: string[]): boolean => {
    return paths.some((path) => {
      const pathPattern = new RegExp(`^${path.replace(/:\w+/g, "\\w+")}$`);
      return pathPattern.test(pathname);
    });
  };

  return (
    <Sidebar setExpandedMain={true}>
      <SidebarItem
        icon={
          <DashboardSVG
            color={pathname === "/dashboard" ? "#fff" : "#00006D"}
          />
        }
        text={"Home"}
        alert={pathname === "/dashboard"}
        active={pathname === "/dashboard"}
        href="/dashboard"
      />

      <SidebarItem
        icon={
          <RentalScheduleSVG
            color={
              pathname === "/dashboard/rentalschedule" ? "#fff" : "#00006D"
            }
          />
        }
        text={"Rental Schedule"}
        alert={pathname === "/dashboard/rentalschedule"}
        active={pathname === "/dashboard/rentalschedule"}
        href="/dashboard/rentalschedule"
      />
      <SidebarItem
        icon={
          <InventorySVG
            color={isActivePath(pathname, inventoryPaths) ? "#fff" : "#00006D"}
          />
        }
        text={"Inventory"}
        alert={isActivePath(pathname, inventoryPaths)}
        active={isActivePath(pathname, inventoryPaths)}
        href="/dashboard/inventory"
      />
      <SidebarItem
        icon={
          <InvoicesSVG
            color={pathname === "/dashboard/invoices" ? "#fff" : "#00006D"}
          />
        }
        text={"Invoices"}
        alert={pathname === "/dashboard/invoices"}
        active={pathname === "/dashboard/invoices"}
        href="/dashboard/invoices"
      />
      <SidebarItem
        icon={
          <SupportSVG
            color={pathname === "/dashboard/support" ? "#fff" : "#00006D"}
          />
        }
        text={"Support"}
        alert={pathname === "/dashboard/support"}
        active={pathname === "/dashboard/support"}
        href="/dashboard/support"
      />
      <SidebarItem
        icon={
          <NewBusinessSVG
            color={pathname === "/dashboard/newbusiness" ? "#fff" : "#00006D"}
          />
        }
        text={"New Business"}
        alert={pathname === "/dashboard/newbusiness"}
        active={pathname === "/dashboard/newbusiness"}
        href="/dashboard/newbusiness"
      />
      <SidebarItem
        icon={
          <Automation
            color={pathname === "/dashboard/automation" ? "#fff" : "#00006D"}
          />
        }
        text={"Automation"}
        alert={pathname === "/dashboard/automation"}
        active={pathname === "/dashboard/automation"}
        href="/dashboard/automation"
      />
    </Sidebar>
  );
}
