import React from "react";
import Main from "../../../components/ui/sidebar/Main.tsx";

const Documents = () => {
  return (
    <div>
      <div>Documents Page</div>
    </div>
  );
};

export default Documents;
