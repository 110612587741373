import React from "react";
import Main from "../../../components/ui/sidebar/Main.tsx";

const Contact = () => {
  return (
    <div>
      <div className="text-black">HAHAH</div>
    </div>
  );
};

export default Contact;
