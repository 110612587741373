import React from "react";
import { Route, Routes } from "react-router";

import Dashboard from "./pages/client/Dashboard/Dashboard.tsx";
import RentalSchedule from "./pages/client/RentalSchedule/RentalSchedule.tsx";
import Inventory from "./pages/client/Inventory/Inventory.tsx";
import Invoices from "./pages/client/Invoices/Invoices.tsx";
import Support from "./pages/client/Support/Support.tsx";
import Contact from "./pages/client/Contact/Contact.tsx";
import Documents from "./pages/client/Documents/Documents.tsx";
import ViewInventory from "./pages/client/Inventory/ViewInventory/ViewInventory.tsx";
import NewBusiness from "./pages/client/NewBusiness/NewBusiness.tsx";
import Automation from "./pages/client/Automation/Automation.tsx";

const DashPaths = () => (
  <Routes>
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/dashboard/rentalschedule" element={<RentalSchedule />} />
    <Route path="/dashboard/inventory" element={<Inventory />} />
    <Route path="/dashboard/invoices" element={<Invoices />} />
    <Route path="/dashboard/support" element={<Support />} />
    <Route path="/dashboard/contact" element={<Contact />} />
    <Route path="/dashboard/documents" element={<Documents />} />
    <Route path="/dashboard/inventorylist" element={<ViewInventory />} />
    <Route path="/dashboard/newbusiness" element={<NewBusiness />} />
    <Route path="/dashboard/automation" element={<Automation />} />
  </Routes>
);

export default DashPaths;
