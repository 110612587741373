import React from "react";

interface ProfileCardProps {
  name: string;
  title: string;
  text: string;
  imageUrl: string;
  profileUrl: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name,
  title,
  text,
  imageUrl,
  profileUrl,
}) => {
  const openProfileUrl = React.useCallback(() => {
    window.open(profileUrl, "_blank");
  }, []);
  return (
    <div className="rounded-lg overflow-hidden max-w-[30%]">
      <div className="flex justify-center items-center p-1 ">
        <div className="p-1 bg-white rounded-full shadow-xl  outline-2">
          <img
            className="rounded-full border border-black h-52 w-52"
            src={imageUrl}
            alt={name}
          />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="font-medium text-xl mb-2 font-roboto">{name}</div>
        <p className="text-black text-base font-light font-roboto">{title}</p>
        <p className="text-black text-base font-light font-roboto">
          <span
            onClick={openProfileUrl}
            className="text-black text-base font-light font-roboto cursor-pointer"
          >
            LinkedIn -
          </span>
          {text}
        </p>
      </div>
    </div>
  );
};

export default ProfileCard;
