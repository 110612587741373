import * as React from "react";
import Cell from "../../../ui/Cell.tsx";
import { AssetInventoryType, DeviceInfoType } from "../../../../types.ts";
import Action from "./Action.tsx";
import { capitalizeFirstLetter } from "../../../../utils/utils.ts";
import { Input, Tooltip } from "@nextui-org/react";
import UpdateRemarks from "./UpdateRemark.tsx";
import ActionQc from "./ActionQc.tsx";

interface Props {
  index: number;
  asset: DeviceInfoType;
  columnKey: React.Key;
  handleDownloadCsv: (item: AssetInventoryType) => void;
  onSubmitRemark: (serial_number: string, remark: string) => void;
}

export default function RenderColumnsQC({
  index,
  asset,
  columnKey,
  handleDownloadCsv,
  onSubmitRemark,
}: Props) {
  switch (columnKey) {
    case "sr_no":
      return <Cell>{index + 1}</Cell>;
    case "serial_number":
      return <Cell>{asset.SerialNumber}</Cell>;
    case "brand":
      return <Cell>{asset.Brand}</Cell>;
    case "model":
      return <Cell>{asset.Model === "" ? "NA" : asset.Model}</Cell>;
    case "state":
      return <Cell>{asset.State}</Cell>;
    case "total_ram":
      return <Cell>{asset.RAMTotal === "" ? "NA" : asset?.RAMTotal}</Cell>;
    case "memory":
      return <Cell>{asset?.SSDType === "" ? "NA" : asset?.SSDType}</Cell>;
    case "category":
      return <Cell>{asset.Category}</Cell>;
    case "status":
      return (
        <Cell>
          <p>Status</p>
        </Cell>
      );

    case "delivery_address":
      return (
        <Cell>
          <Tooltip content={asset.Location} size="sm">
            {capitalizeFirstLetter(asset.Location, 20)}
          </Tooltip>
        </Cell>
      );
    case "description":
      return <Cell>{asset.QCRemarks === "" ? "NA" : asset.QCRemarks}</Cell>;
    case "action":
      return (
        <div className="flex justify-center items-center">
          <ActionQc
            item={asset}
            handleDownloadCsv={() => {}}
            downloadInvoice={() => {}}
          />
        </div>
      );
  }
}
