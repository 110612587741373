import Button from "../../../ui/Button.tsx";
import Input from "../../../ui/Input.tsx";
import { Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { AssetInventoryType } from "../../../../types.ts";
import { MdContentCopy } from "react-icons/md";

interface Props {
  device: AssetInventoryType;
  onSubmitRemark: (serial_number: string, remark: string) => void;
}

export default function UpdateRemarks({ device, onSubmitRemark }: Props) {
  const handleSubmit = React.useCallback(
    ({ remark }: { remark: string }) => {
      return onSubmitRemark(device?.SerialNumber, remark);
    },
    [device?.SerialNumber, onSubmitRemark],
  );

  const validationSchema = Yup.object().shape({
    remark: Yup.string().required("Remark is required"),
  });

  return (
    <Formik
      initialValues={{ remark: device?.Remark ?? "" }}
      onSubmit={handleSubmit}
      validateOnBlur
      validateOnChange
      validationSchema={validationSchema}
      enableReinitialize
      key={`remark-${device?.Remark}`}
    >
      <Form>
        <Input
          name="remark"
          size="sm"
          endContent={
            <Button isIconOnly variant="light">
              <MdContentCopy />
            </Button>
          }
        />
      </Form>
    </Formik>
  );
}
