import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/common/Header";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import MeetingSvg from "../../assets/images/MeetingsSvg.svg";
import {
  AddressCard,
  AddressCardLeft,
} from "../../components/pages/work/FlowPoint.tsx";
import { Colors } from "../../assets/colors.js";
import { VerticalLineSvg } from "../../assets/images/Images.js";
import Contactform from "../../components/homeelments/Contactform.js";
import Calculator from "../../components/common/Calculator.tsx";
const Works = () => {
  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          Works
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
        {/* Add any other meta tags as needed */}
      </Helmet>

      <div className="w-full ">
        <Header />
        <div className=" w-screen">
          <HeadingBanner
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/WorksBanner1.png"
            }
            text="How Leasing with Connect Works "
            desc={
              "Building Tailor Made Asset Leasing Solutions as per your needs "
            }
          />
        </div>
        <div className="flex justify-center ">
          <div className="flex flex-col justify-center items-center">
            <div className=" w-[80%] ">
              <p className=" text-base font-normal font-roboto w-full text-black text-center my-[8%]">
                Connect prides in its 50+ member strong Client Relationship
                Team, present PAN India, who work with you to understand your
                business, your needs and create a leasing plan for you to help
                you achieve your objectives. Our team offices in Mumbai, Delhi,
                Bangalore, Chennai, Pune and Okhla proactively works with you
                and your team to deeply understand your requirements, and uses
                its 40+ years of industry expertise to build and propose a
                leasing plan tailor made for you.
              </p>
              <div>
                <AddressCard
                  cardtitle="Introducing Meetings"
                  content="We understand that one size does not fit all. That’s why our team carefully analyses every global. We understand that one size does not fit all. That’s why our team carefully analyses every global"
                  Banner={
                    "https://storage.googleapis.com/connectrpl_images/companywebsite/workimg1.svg"
                  }
                  left={false}
                />
                <div className="flex flex-row justify-center ">
                  <VerticalLineSvg width={10} height={100} />
                </div>
                <AddressCardLeft
                  cardtitle="Understanding your Needs"
                  content="We understand that one size does not fit all. That’s why our team carefully analyses every global. We understand that one size does not fit all. That’s why our team carefully analyses every global"
                  Banner={
                    "https://storage.googleapis.com/connectrpl_images/companywebsite/workimg2.svg"
                  }
                  left={true}
                />
                <div className="flex flex-row justify-center ">
                  <VerticalLineSvg width={10} height={100} />
                </div>
                <AddressCard
                  cardtitle="Proposal"
                  content="We understand that one size does not fit all. That’s why our team carefully analyses every global. We understand that one size does not fit all. That’s why our team carefully analyses every global"
                  Banner={
                    "https://storage.googleapis.com/connectrpl_images/companywebsite/workimg3.svg"
                  }
                  left={false}
                />
                <div className="flex flex-row justify-center ">
                  <VerticalLineSvg width={10} height={100} />
                </div>
                <AddressCardLeft
                  cardtitle="Documentation and Deal Sign Off"
                  content="We understand that one size does not fit all. That’s why our team carefully analyses every global. We understand that one size does not fit all. That’s why our team carefully analyses every global"
                  Banner={
                    "https://storage.googleapis.com/connectrpl_images/companywebsite/workimg4.svg"
                  }
                  left={true}
                />
                <div className="flex flex-row justify-center ">
                  <VerticalLineSvg width={10} height={100} />
                </div>
                <AddressCard
                  cardtitle="After Sales Servicing and Contacts"
                  content="We understand that one size does not fit all. That’s why our team carefully analyses every global. We understand that one size does not fit all. That’s why our team carefully analyses every global"
                  Banner={
                    "https://storage.googleapis.com/connectrpl_images/companywebsite/workimg5.svg"
                  }
                  left={false}
                />
                <div className="flex flex-row justify-center">
                  <VerticalLineSvg width={10} height={100} />
                </div>
                <AddressCardLeft
                  cardtitle="Rental Assistance"
                  content="We understand that one size does not fit all. That’s why our team carefully analyses every global. We understand that one size does not fit all. That’s why our team carefully analyses every global"
                  Banner={
                    "https://storage.googleapis.com/connectrpl_images/companywebsite/workimg6.svg"
                  }
                  left={true}
                />
                <div className="flex flex-row justify-center ">
                  <VerticalLineSvg width={10} height={100} />
                </div>
                <AddressCard
                  cardtitle="End of Term Services"
                  content="We understand that one size does not fit all. That’s why our team carefully analyses every global. We understand that one size does not fit all. That’s why our team carefully analyses every global"
                  Banner={
                    "https://storage.googleapis.com/connectrpl_images/companywebsite/workimg7.svg"
                  }
                  left={true}
                />{" "}
                <div className="flex flex-row justify-center ">
                  <VerticalLineSvg width={10} height={100} />
                </div>
                <AddressCardLeft
                  cardtitle="Post Term Involvements"
                  content="We understand that one size does not fit all. That’s why our team carefully analyses every global. We understand that one size does not fit all. That’s why our team carefully analyses every global"
                  Banner={
                    "https://storage.googleapis.com/connectrpl_images/companywebsite/workimg8.svg"
                  }
                  left={true}
                />
              </div>
            </div>
            <div className="mt-[5%] py-10 px-4 w-screen">
              {/* <Calculator /> */}
              <Contactform />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
