import React from "react";
import Spacer from "../../../components/ui/Spacer.tsx";
import RentalNumbersList from "../../../components/pages/client/rentalSchedule/rental-number-list/List.tsx";
import { ClientRentalNumbersList } from "../../../types.ts";
import useApi from "../../../hooks/useApi.tsx";
import { GetRentalListApi } from "../../../apis.ts";
import secureLocalStorage from "react-secure-storage";

// Define the UserData interface
interface UserData {
  client_name: string;
  client_code: string;
  name: string;
  email: string;
}

const RentalSchedule = () => {
  const { makeApiCall } = useApi();

  const [rentalLoading, setRentalLoading] = React.useState(false);
  const [clientRentalNumbers, setClientIRentalNumbers] = React.useState<
    ClientRentalNumbersList[]
  >([]);
  const [userData, setUserData] = React.useState<UserData | null>(null); // State to store user data

  // Rental schedule get API call
  React.useEffect(() => {
    let data = secureLocalStorage.getItem("userinfo");
    if (data) {
      // Parse the data if necessary
      const parsedData: UserData = JSON.parse(data as string);
      setUserData(parsedData); // Set the user data in state

      if (parsedData.client_code) {
        setRentalLoading(true);
        makeApiCall(GetRentalListApi(parsedData.client_code))
          .then((response) => {
            if (response !== undefined) {
              setClientIRentalNumbers(response?.data);
            }
          })
          .catch((error) => console.error(error))
          .finally(() => setRentalLoading(false));
      }
    }
  }, [makeApiCall]);

  const downloadDocument = React.useCallback((name: string, url: string) => {
    const pdfUrl = url;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank"; // Open in a new tab/window
    link.download = `${name}.pdf`; // Specify the desired file name for the downloaded PDF
    link.click();
  }, []);

  const handleAddRental = React.useCallback(() => {
    console.log("Handle rental@!!");
  }, []);

  return (
    <div className="text-black bg-pageBackground px-10 min-h-screen ">
      <Spacer size="sm" />
      <RentalNumbersList
        rentalNumbers={clientRentalNumbers}
        loading={rentalLoading}
        downloadRental={downloadDocument}
        onUpdateRental={handleAddRental}
      />
    </div>
  );
};

export default RentalSchedule;
