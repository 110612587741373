import React from "react";
import Spacer from "../../../../components/ui/Spacer.tsx";
import secureLocalStorage from "react-secure-storage";
import useApi from "../../../../hooks/useApi.tsx";
import {
  GetScheduleNumberInventoryInfo,
  GetScheduleNumberQCInfo,
  UpdateDeviceRemarkApi,
} from "../../../../apis.ts";
import InventoryDataList from "../../../../components/pages/client/inventorydata-list/List.tsx";
import useToast from "../../../../hooks/useToast.tsx";
import { AssetInventoryType, DeviceInfoType } from "../../../../types.ts";
import InventoryDataListQc from "../../../../components/pages/client/inventorydata-list/ListQc.tsx";

// Define the UserData interface
interface UserData {
  client_name: string;
  client_code: string;
  name: string;
  email: string;
}

const ViewInventory = () => {
  const { makeApiCall } = useApi();
  //   const { schedule_number: scheduleNumberParam } = useParams();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const scheduleNumberParam = params.get("schedule_number");
  const statusParam = params.get("status");

  const { showToast } = useToast();

  const [deviceInfo, setDeviceInfo] = React.useState<AssetInventoryType[]>([]);
  const [deviceInfoQc, setDeviceInfoQc] = React.useState<DeviceInfoType[]>([]);

  const [loading, setLoading] = React.useState(false);
  const [scheduleNumber, setScheduleNumber] = React.useState<string>(
    scheduleNumberParam || "",
  ); // Ensure it is a string

  const [status, setStatus] = React.useState<string>(statusParam || ""); // Ensure it is a string
  const [userData, setUserData] = React.useState<UserData | null>(null); // State to store user data

  // Rental schedule get API call
  React.useEffect(() => {
    let data = secureLocalStorage.getItem("userinfo");
    if (data && scheduleNumber !== "") {
      // Parse the data if necessary
      const parsedData: UserData = JSON.parse(data as string);
      setUserData(parsedData); // Set the user data in state
      if (parsedData.client_code) {
        setLoading(true);

        if (Number(status) == 0) {
          makeApiCall(
            GetScheduleNumberInventoryInfo(
              parsedData.client_code,
              scheduleNumber,
            ),
          )
            .then((response) => {
              if (response !== undefined) {
                setDeviceInfo(response);
              }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
        } else {
          makeApiCall(
            GetScheduleNumberQCInfo(parsedData.client_code, scheduleNumber),
          )
            .then((response) => {
              if (response !== undefined) {
                setDeviceInfoQc(response);
              }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
        }
      }
    }
  }, [makeApiCall, scheduleNumber, status]);

  const handleDeviceRemark = React.useCallback(
    (serial_number: string, remark: string) => {
      if (userData?.client_code == "") {
        showToast("Remark update failed, Client code not found", {
          type: "error",
        });
      }
      return makeApiCall(
        UpdateDeviceRemarkApi(
          userData?.client_code || "",
          serial_number,
          remark,
        ),
      )
        .then(() =>
          showToast("Remark update successfully", { type: "success" }),
        )
        .catch(() => showToast("Remark update failed", { type: "error" }));
    },
    [makeApiCall, showToast, userData],
  );

  return (
    <div className="text-black bg-pageBackground px-10 min-h-screen">
      <Spacer size="sm" />

      {Number(status) == 0 ? (
        <InventoryDataList
          deviceInfo={deviceInfo}
          loading={loading}
          onSubmitRemark={handleDeviceRemark}
        />
      ) : (
        <InventoryDataListQc
          deviceInfo={deviceInfoQc}
          loading={loading}
          onSubmitRemark={handleDeviceRemark}
        />
      )}
    </div>
  );
};

export default ViewInventory;
