import React, { useState, useEffect } from "react";
import axios from "axios";
import Spacer from "../../../components/ui/Spacer.tsx";
import { Button, Textarea } from "@nextui-org/react";
import { servicesData } from "../../../assets/data/index.js";
import ServiceItem from "../../../components/common/ServicesItemCard.tsx";
import { ToastContainer, toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import useApi from "../../../hooks/useApi.tsx";
import { GetClientSupportApi } from "../../../apis.ts";

interface ApiResponse {
  user_name: string;
  user_email: string;
  manager_name: string;
  manager_email: string;
  designation: string;
  phone: string;
}
// Define the UserData interface
interface UserData {
  client_name: string;
  client_code: string;
  name: string;
  email: string;
}

const NewBusiness = () => {
  const { makeApiCall } = useApi();

  const notify = (text: string) => toast.success(text);
  const notifyfail = (text: string) => toast.error(text);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [responseData, setResponseData] = React.useState<ApiResponse>();
  const payload = {
    name: formData.name,
    recipient: formData.email,
    subject: "Service Request Received",
    body: formData.message,
    account_manager: responseData?.user_email,
  };

  React.useEffect(() => {
    let data = secureLocalStorage.getItem("userinfo");
    if (data) {
      // Parse the data if necessary
      const parsedData: UserData = JSON.parse(data as string);
      setUserData(parsedData); // Set the user data in state
      if (parsedData.client_code) {
        setLoading(true);
        makeApiCall(GetClientSupportApi(parsedData.client_code))
          .then((response) => {
            if (response !== undefined) {
              setResponseData(response); // Set the API response data in state
            }
          })
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
      }
    }
  }, [makeApiCall]);

  useEffect(() => {
    let data = secureLocalStorage.getItem("userinfo");
    if (data) {
      const parsedData: UserData = JSON.parse(data as string);
      setUserData(parsedData);
      setFormData({
        name: parsedData.client_name,
        email: parsedData.email,
        message: "",
      });
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      console.log(payload);
      const response = await axios.post(
        "https://feedbackservice-u5ab3vfhaa-uw.a.run.app/sendclientsidemail",
        payload,
      );
      setIsLoading(false);
      notify("Message sent!");
    } catch (error) {
      setIsLoading(false);
      notifyfail("Error occurred!!");
      setError(error as Error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div>
        <div className="text-black bg-pageBackground px-10 min-h-screen">
          <Spacer size="sm" />
          <p className="text-black text-lg leading-8 font-roboto font-normal mt-[2%]">
            New Business
          </p>
          <Spacer size="xs" />
          <div className="bg-white w-[90%] px-[1%] py-[1%] shadow-box rounded-lg">
            <p className="text-black text-base leading-8 font-roboto font-normal">
              New Service Request
            </p>
            <form onSubmit={handleSubmit}>
              <Textarea
                onChange={handleChange}
                className="w-[30%] mt-6"
                name="message"
              />
              <Button
                isLoading={isLoading}
                className="bg-buttonprimary mt-5"
                type="submit"
              >
                <p className="text-white">Submit</p>
              </Button>
            </form>
          </div>

          <p className="text-black text-lg leading-8 font-roboto font-normal my-[2%]">
            Services
          </p>
          <div className="bg-white w-[90%] px-[1%] py-[1%] shadow-box rounded-lg">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {servicesData.map((item, index) => (
                <ServiceItem
                  key={index}
                  imageUrl={item.imageUrl}
                  text={item.text}
                  url={item.url}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBusiness;
