import * as React from "react";
import Row from "../../../ui/Row.tsx";
import { Tooltip } from "@nextui-org/react";
import { ClientScheduleNumbersList } from "../../../../types.ts";

interface Props {
  item: ClientScheduleNumbersList;
  downloadRental: () => void;
  onViewInventory: (
    schedule_number: string,
    item: ClientScheduleNumbersList,
  ) => void;
  downloadQrCodes: (scheduleNumber: string) => void;
}

export default function Action({
  item,
  downloadRental,
  onViewInventory,
  downloadQrCodes,
}: Props) {
  const handleDownloadQrCodes = React.useCallback(() => {
    if (item?.ScheduleNumber !== undefined) {
      downloadQrCodes(item.ScheduleNumber);
    }
  }, [downloadQrCodes, item?.ScheduleNumber]);

  return (
    <div className="flex flex-row justify-end gap-x-3 2xl:gap-x-4 whitespace-nowrap">
      <a
        onClick={() => {
          onViewInventory(item.ScheduleNumber, item);
        }}
        className="text-textColorGrey  cursor-pointer font-roboto font-normal"
      >
        View Inventory
      </a>

      {/* <Tooltip content="download" size="sm">
        <a
          onClick={downloadRental}
          href="#"
          className="text-linkColor underline cursor-pointer font-roboto font-normal text-xs 2xl:text-base"
        >
          Download
        </a>
      </Tooltip> */}

      <a
        onClick={handleDownloadQrCodes}
        href="#"
        className="text-textColorGrey  cursor-pointer font-roboto font-normal"
      >
        QR Code
      </a>
    </div>
  );
}
