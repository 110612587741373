import React from "react";
import { Route, Routes } from "react-router";
import Homepage from "./pages/homescreen/Homepage";
import Aboutus from "./pages/aboutus/Aboutus";
import Whyrent from "./pages/whyrent/Whyrent";
import Solutions from "./pages/solutions/Solutions";
import Csr from "./pages/csr/Csr";
import Blogs from "./pages/blogs/Blogs";
import Industries from "./pages/industries/Industries";
import Career from "./pages/career/Career";
import Reachus from "./pages/reachus/Reachus";
import Blogpage from "./pages/blogs/Blogpage";
import Postblog from "./pages/postblog/Postblog";
import PrivacyPolicy from "./pages/privacypolicy/Privacypolicy";
import Works from "./pages/works/Works";
import PartnerWithUs from "./pages/partnerus/PartnerUs";
import Services from "./pages/solutions/Services";
import Startups from "./pages/solutions/Startups";
import Corporates from "./pages/solutions/Corporates";
import Msme from "./pages/solutions/Msme";
import AppleforBusiness from "./pages/solutions/AppleForBusiness";
import AssetDisposalService from "./pages/solutions/AssetDisposalService";
import ValueAddedService from "./pages/solutions/ValueAdded";
import Login from "./pages/client/login/Login.tsx";
import ComingSoon from "./pages/homescreen/ComigSoon.js";

const Paths = () => (
  <Routes>
    <Route path="/" element={<ComingSoon />} />
    <Route path="/aboutus" element={<Aboutus />} />
    <Route path="/whyrent" element={<Whyrent />} />
    <Route path="/solutions" element={<Solutions />} />
    <Route path="/csr" element={<Csr />} />
    <Route path="/blogs" element={<Blogs />} />
    <Route path="/industries" element={<Industries />} />
    <Route path="/career" element={<Career />} />
    <Route path="/reachus" element={<Reachus />} />
    <Route path="/post/:slug" element={<Blogpage />} />
    <Route path="/postblog" element={<Postblog />} />
    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
    <Route path="/works" element={<Works />} />
    <Route path="/partnerus" element={<PartnerWithUs />} />
    <Route path="/services" element={<Services />} />
    <Route path="/startups" element={<Startups />} />
    <Route path="/corporates" element={<Corporates />} />
    <Route path="/msme" element={<Msme />} />
    <Route path="/appleforbusiness" element={<AppleforBusiness />} />
    <Route path="/assetdisposal" element={<AssetDisposalService />} />
    <Route path="/valueadded" element={<ValueAddedService />} />
    <Route path="/login" element={<Login />} />
  </Routes>
);

export default Paths;
