import React, { useCallback, useEffect } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Input,
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  Avatar,
  Button,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { ProfileSVG } from "../../../assets/images/Images";
import { UserDataApi } from "../../../apis.ts";
import useApi from "../../../hooks/useApi.tsx";
import useToast from "../../../hooks/useToast.tsx";
import secureLocalStorage from "react-secure-storage";
import { DownArrow } from "../../../assets/images/downarrow.tsx";
interface UserData {
  client_name: string;
  client_code: string;
  name: string;
  email: string;
  // Add other properties as needed
}

const DashHeader: React.FC = () => {
  const navigate = useNavigate();
  const { makeApiCall } = useApi();
  const { showToast } = useToast();

  const userdata = secureLocalStorage.getItem("userinfo");

  const handleLogout = useCallback((): void => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    calluserData();
  }, []);

  const calluserData = useCallback(() => {
    return makeApiCall(UserDataApi())
      .then((response) => {
        if (response?.message === true) {
          secureLocalStorage.setItem(
            "userinfo",
            JSON.stringify(response?.data),
          );
        } else {
          showToast("Something went wrong!!", { type: "error" });
        }
        return true;
      })
      .catch((error) => {
        console.error("userdata Error:- ", error);
        return false;
      })
      .finally(() => {});
  }, [makeApiCall, showToast]);

  const parsedUserdata: UserData | null = userdata
    ? (JSON.parse(userdata as string) as UserData)
    : null;

  return (
    <div className="flex bg-white justify-end items-center  p-3">
      <div className="flex justify-between w-[82%]">
        <div></div>
        <div className="flex justify-start items-center mr-10">
          <Dropdown placement="bottom-end">
            <DropdownTrigger>
              <Link>
                <ProfileSVG />
                <p className="text-black font-roboto font-normal ml-2 mr-5">
                  {parsedUserdata ? `${parsedUserdata?.name}` : "Welcome"}
                </p>
                <DownArrow />
              </Link>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Profile Actions"
              variant="flat"
              className="bg-cyan-50 border rounded-md"
            >
              <DropdownItem key="profile" className="h-14 gap-2">
                <p className="text-black font-roboto font-medium">
                  Signed in as
                </p>
                <p className="text-black font-roboto font-medium">
                  {parsedUserdata ? `${parsedUserdata?.name}` : "Welcome"}
                </p>
              </DropdownItem>
              <DropdownItem
                className="text-black font-roboto text-base"
                key="logout"
                color="danger"
                onClick={handleLogout}
              >
                Log Out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default DashHeader;
