import { onePiece, baki } from "./utils/AxiosInterceptor.tsx";
import secureLocalStorage from "react-secure-storage";

export const LoginApi = (email: string, password: string) => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);
  return onePiece.post("/client-login", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const UserDataApi = () => {
  return onePiece.get("/get-clientinfo", {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
  });
};

//rental api get
export const GetRentalListApi = (clientCode: string) => {
  return onePiece.get(`/client-rental-schedule/${clientCode}`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
  });
};

//invoice list api

export const GetInvoiceListApi = (clientCode: string) => {
  return onePiece.get(`/client-invoice/${clientCode}`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
  });
};

export const GetScheduleNumbersApi = (clientCode: string) => {
  return onePiece.get(`/client-schedules/${clientCode}`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
  });
};

export const GetClientSupportApi = (clientCode: string) => {
  return onePiece.get(`/client-support/${clientCode}`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
  });
};

export const GetScheduleNumberInventoryInfo = (
  clientCode: string,
  scheduleNumber: string,
) => {
  return onePiece.get(
    `/client-inventory-details/${clientCode}/${scheduleNumber}`,
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },
  );
};

export const GetScheduleNumberQCInfo = (
  clientCode: string,
  scheduleNumber: string,
) => {
  return onePiece.get(`/client-device/${clientCode}/${scheduleNumber}`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
  });
};

export const DownloadQrCodes = (clientCode: string, scheduleNumber: string) => {
  return onePiece.get(`/client-qr/${clientCode}/${scheduleNumber}`, {
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      Company: "connect",
    },
  });
};

export const UpdateDeviceRemarkApi = (
  clientCode: string,
  serialNumber: string,
  remark: string,
) => {
  return onePiece.put(
    `/client-device-remarks/${clientCode}/${serialNumber}`,
    {
      remark,
    },
    {
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },
  );
};

export const GetUpcomingQcClient = (
  start_date: string,
  end_date: string,
  clientCode: string,
) => {
  return onePiece.post(
    `/client-upcoming-qc/${clientCode}`,
    { start_date, end_date },
    {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },
  );
};

//latest update data api
export const GetlatestListApi = () => {
  return onePiece.get(`/client-latestupdates`, {
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
  });
};

//dashboard client user data
export const GetMraUrlApi = (clientCode: string) => {
  return onePiece.get(`/client-mra/${clientCode}`, {
    params: {
      code: clientCode,
    },
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
    },
  });
};
