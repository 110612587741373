import React from "react";
import { ClientScheduleNumbersList } from "../../../types.ts";
import Spacer from "../../../components/ui/Spacer.tsx";
import ScheduleNumbersList from "../../../components/pages/client/inventory-number-list/List.tsx";
import secureLocalStorage from "react-secure-storage";
import useApi from "../../../hooks/useApi.tsx";
import { DownloadQrCodes, GetScheduleNumbersApi } from "../../../apis.ts";
import { toast } from "react-toastify";

// Define the UserData interface
interface UserData {
  client_name: string;
  client_code: string;
  name: string;
  email: string;
}

const Inventory = () => {
  const { makeApiCall } = useApi();

  const [clientScheduleNumbers, setClientScheduleNumbers] = React.useState<
    ClientScheduleNumbersList[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState<UserData | null>(null); // State to store user data
  const [isDownloading, setIsDownloading] = React.useState(false);

  // Rental schedule get API call
  React.useEffect(() => {
    let data = secureLocalStorage.getItem("userinfo");
    const parsedData: UserData = JSON.parse(data as string);
    setUserData(parsedData);
    if (data) {
      // Parse the data if necessary
      const parsedData: UserData = JSON.parse(data as string);
      setUserData(parsedData); // Set the user data in state
      if (parsedData.client_code) {
        setLoading(true);
        makeApiCall(GetScheduleNumbersApi(parsedData.client_code))
          .then((response) => {
            if (response !== undefined) {
              setClientScheduleNumbers(response);
            }
          })
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
      }
    }
  }, [makeApiCall]);

  const downloadDocument = React.useCallback(() => {
    console.log("DOWNLOAD INVENTORY");
  }, []);

  const downloadQrCodes = React.useCallback(
    (scheduleNumber: string) => {
      let data = secureLocalStorage.getItem("userinfo");
      const parsedData: UserData = JSON.parse(data as string);
      setUserData(parsedData);
      setIsDownloading(false);
      toast.promise(
        new Promise((resolve, reject) => {
          return makeApiCall(
            DownloadQrCodes(parsedData.client_code, scheduleNumber),
          )
            .then((response) => {
              const blobUrl = URL.createObjectURL(response);
              const anchor = document.createElement("a");
              anchor.href = blobUrl;
              anchor.download = `${scheduleNumber}.zip`;
              anchor.style.display = "none";
              document.body.appendChild(anchor);
              anchor.click();

              URL.revokeObjectURL(blobUrl);
              document.body.removeChild(anchor);
              resolve(null);
            })
            .catch((err) => {
              console.error("Error fetching or downloading ZIP file:", err);
              reject(new Error("Qr codes download failed"));
            })
            .finally(() => setIsDownloading(false));
        }),
        {
          pending: "Qr codes downloading",
          success: "Qr codes download successfully",
          error: "Qr codes download failed",
        },
      );
    },
    [makeApiCall],
  );

  return (
    <div className="text-black bg-pageBackground px-10 min-h-screen">
      <Spacer size="sm" />
      <ScheduleNumbersList
        scheduleNumbers={clientScheduleNumbers}
        loading={loading}
        downloadInventory={downloadDocument}
        downloadQrCodes={downloadQrCodes}
      />
    </div>
  );
};

export default Inventory;
