import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";
import HeaderButton from "./HeaderButton";
import PhoneLogo from "../../../src/assets/images/phone.svg";

const Header = () => {
  let [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <div className=" flex justify-center items-center  h-[8vh]">
        <div className="flex justify-between border-b-0 border-gray-400  w-[80%]  ">
          <div className="relative  w-28 my-4  flex justify-center items-center ">
            <button onClick={() => navigate("/")}>
              <img
                src="https://storage.googleapis.com/connectrpl-landing-pagev3/siteimages/logonew.png"
                alt="new"
                style={{ width: "100px" }}
              />
            </button>
          </div>
          {/* public\logonew.png */}
          <div
            onClick={() => {
              setOpen(!open);
            }}
            className="  absolute right-5  cursor-pointer lg:hidden"
          >
            <Hamburger size={20} />
          </div>
          <div className="flex flex-row">
            <div
              className={`justify-center items-center   overflow-hidden  z-40 lg:flex lg:items-center lg:pb-0   absolute lg:static  lg:mr-6 left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in ${
                open ? "top-20 " : "top-[-490px]"
              }`}
            >
              <div className="block lg:hidden">
                <HeaderButton
                  buttontext="Home"
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </div>

              <HeaderButton
                buttontext="About"
                onClick={() => {
                  navigate("/aboutus");
                }}
              />
              {/* <HeaderButton
                buttontext="Works"
                onClick={() => {
                  navigate("/works");
                }}
              /> */}
              {/* <HeaderButton
                buttontext="Partner"
                onClick={() => {
                  navigate("/partnerus");
                }}
              /> */}
              <HeaderButton
                buttontext="Services"
                onClick={() => {
                  navigate("/services");
                }}
              />

              <HeaderButton
                buttontext="Benefits"
                onClick={() => {
                  navigate("/blogs");
                }}
              />
              <HeaderButton
                buttontext="Contact Us"
                onClick={() => {
                  navigate("/reachus");
                }}
              />
              <div>|</div>
            </div>

            <div className="hidden lg:flex justify-center items-center  mr-6   text-sm">
              <div className="flex flex-row justify-center items-center  ">
                <img
                  width={14}
                  src={PhoneLogo}
                  alt="React Logo"
                  className=" mr-2 "
                />
                <p className="text-black font-roboto font-light text-xs sm:text-base md:text-base lg:text-base">
                  +91 22 69527600
                </p>
              </div>

              <div className="flex flex-row justify-center items-center ml-4  ">
                <button
                  className=" hover:bg-blue-700  py-2 px-4 rounded"
                  onClick={() => {
                    navigate("/login");
                  }}
                  style={{ backgroundColor: "#00006A", color: "white" }}
                >
                  <p className="font-roboto text-white font-normal">
                    MyConnect
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
