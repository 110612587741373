import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { updateblogs } from "../../redux/slice.js";
import { firestore } from "../../lib/db.js";
import Header from "../../components/common/Header.js";
import HeadingBanner from "../../components/common/HeadingBanner.tsx";
import BlogCard from "../../components/pages/blogs/BlogCard.tsx";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]); // Define the state with the Blog type
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchBlogs = async () => {
      const querySnapshot = await getDocs(collection(firestore, "blogs"));
      const blogsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        title: doc.data().title, // Assuming 'title' is a field in your Firestore document
        content: doc.data().content, // Assuming 'content' is a field
        createdAt: doc.data().createdAt,
        description: doc.data().description,
        image: doc.data().image,
        updatedAt: doc.data().updatedAt,
        slug: doc.data().slug,
        metadata: doc.data().metadata,
        author: doc.data().author,
        // Map other fields as needed
      }));

      setBlogs(blogsArray);

      dispatch(updateblogs(blogsArray));
    };

    fetchBlogs();
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>
          Asset Leasing and Financing | Connect Residuary Private Limited |
          solutions
        </title>
        <meta
          name="description"
          content="Connect Residuary Pvt Limited is a well-established asset leasing brand in India dealing in all types of IT Assets, Fit-outs, Plant and machinery etc. We have been leased over 2000cr + of assets in past 12 years of operations."
        />
        <meta
          name="keywords"
          content="leasing, rent, asset on rent, laptop on rent, leasing, laptop on lease, Corporate Asset Purchase, IT asset leasing, equipment leasing, Financing, small business financing, equipment financing, vehicle financing, asset management, Leaseback, capital equipment, business loans, commercial loans, equipment rentals, SME, tax benefits of leasing, lease accounting"
        />
      </Helmet>

      <div className="w-full ">
        <Header />
        <div className=" w-screen">
          <HeadingBanner
            imageUrl={
              "https://storage.googleapis.com/connectrpl_images/companywebsite/BlogSubBanner.png"
            }
            text="Blogs"
            desc={"Our Solutions, your growth"}
          />
        </div>
        <div className="flex justify-center ">
          <div className=" w-[80%] ">
            <p className=" text-base font-normal font-roboto w-full text-black text-center my-10">
              Connect prides in its 50+ member strong Client Relationship Team,
              present PAN India, who work with you to understand your business,
              your needs and create a leasing plan for you to help you achieve
              your objectives. Our team offices in Mumbai, Delhi, Bangalore,
              Chennai, Pune and Okhla proactively works with you and your team
              to deeply understand your requirements, and uses its 40+ years of
              industry expertise to build and propose a leasing plan tailor made
              for you.
            </p>
            <div className="flex-shrink-0   text-[#1e0e62] mt-10 mb-[5%]  sm:text-4xl text-center font-roboto text-5xl  leading-[64px] ">
              <span className="font-light  ">Blogs</span>
            </div>
            <div className="ml-10  grid grid-cols-1 sm:grid-cols-2 gap-6">
              {blogs.map((blog) => (
                <BlogCard key={blog.id} blog={blog} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
