import * as React from "react";
import { MoreVertical, ChevronLast, ChevronFirst } from "lucide-react";
import { useContext, createContext, useState, ReactNode, FC } from "react";
import { Colors } from "../../../assets/colors";
import { useNavigate } from "react-router-dom";

interface SidebarContextProps {
  expanded: boolean;
}

interface SidebarProps {
  children: ReactNode;
  setExpandedMain: boolean;
}

interface SidebarItemProps {
  icon: ReactNode;
  text: string;
  active?: boolean;
  alert?: boolean;
  href: string;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined,
);

const Sidebar: FC<SidebarProps> = ({ children, setExpandedMain }) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const name: string = "Name";
  const email: string = "Email.com";

  return (
    <div>
      {/* Sidebar for large devices */}
      <aside
        id="logo-sidebar"
        className={`fixed top-0  hidden sm:block left-0 z-40 w-64 pt-0 h-screen sm:translate-x-0 transition-all ${
          expanded ? "w-[18%] hidden sm:block" : "w-[5%] hidden sm:block"
        }`}
      >
        <nav
          className="h-full flex flex-col shadow-sm"
          style={{
            background: "white",
          }}
        >
          <div className="p-4 pb-2 flex justify-between items-center ">
            <img
              src="https://storage.googleapis.com/connectrpl_images/crpl%20logo%203.svg"
              className={`overflow-hidden transition-all ${expanded ? "w-48" : "w-0"}`}
              alt=""
            />
            {/* <button
              onClick={() => {
                setExpandedMain((curr) => !curr);
                setExpanded((curr) => !curr);
              }}
              className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
            >
              {expanded ? <ChevronFirst /> : <ChevronLast />}
            </button> */}
          </div>

          <SidebarContext.Provider value={{ expanded }}>
            <ul className="flex-1 px-8 mt-4">{children}</ul>
          </SidebarContext.Provider>
        </nav>
      </aside>
    </div>
  );
};

export const SidebarItem: FC<SidebarItemProps> = ({
  icon,
  text,
  active,
  alert,
  href,
}) => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("SidebarItem must be used within a SidebarContext");
  }
  const { expanded } = context;
  const navigate = useNavigate();

  const navigateTabs = React.useCallback(() => {
    // window.open('/services', '_blank');
    navigate(href);
  }, []);

  return (
    <li
      onClick={navigateTabs}
      style={{ backgroundColor: active ? "#00006D" : "#fff" }}
      className={`relative flex items-center py-2 px-3 my-3 font-medium rounded-md cursor-pointer  group  ${active ? " text-white" : ""}`}
    >
      {icon}
      <span
        className={`overflow-hidden font-normal font-roboto transition-all  ${active ? " font-medium" : ""} ${expanded ? "w-52 ml-4 text-base" : "w-0"}`}
      >
        {text}
      </span>

      {!expanded && (
        <div
          className={`absolute left-full font-roboto rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}
        >
          {text}
        </div>
      )}
    </li>
  );
};

export default Sidebar;
